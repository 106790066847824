const errorsTrad = {
  numero: "Le numéro du mandat est manquant",
  isExclusif: "Le mandat doit être exclusif ou non exclusif",
  typeVente: "Le type de vente est manquant",
  lieuDeVente: "Le lieu de vente est manquant",
  mandants: "Le ou les mandant est/sont manquant(s)",
  /**Champs Mandants */
  civilite: "La civilité est manquante",
  nom: "Le nom est manquant",
  prenom: "Le prénom est manquant",
  dateNaissance: "La date de naissance est manquante",
  nomSociete: "Le nom de la société est manquant",
  capital: "Le capital est manquant",
  rcs: "Le RCS est manquant",
  rcsVille: "La ville du RCS est manquante",
  rcsRepresentant: "Le représentant du RCS est manquant",
  portable: "Le portable est manquant",
  email: "L'email est manquant",
  nomTuteur: "Le nom du tuteur est manquant",
  decisionTuteur: "La décision du tuteur est manquante",
  tribunalTuteur: "Le tribunal du tuteur est manquant",
  dateTribunalTuteur: "La date du tribunal du tuteur est manquante",

  /** Champs Bien */
  appartementT: "Les spécification de l'appartement sont manquantes",
  lot: "Le lot est manquant",
  tantieme: "Le tantième est manquant",
  adresse: "L'adresse est manquante",
  composition: "La composition du bien est manquante",
  surface: "La surface du bien est manquante",
  valeurVenale: "La valeur vénale du bien est manquante",
  typeBien: "Le type de bien est manquant",
  /** Champs Prix */
  remuneration: "La rémunération est manquante",
  remunerationPayeur: "Le payeur de la rémunération est manquant",
  signataire: "Le signataire est manquant",
  /** Champs Ventilation */
  ventilationsCount:
    "Les ventilation de prix ne sont pas aux nombre minimum de 2",
  bouquet: "Le bouquet est manquant",
  renteViagere: "La rente viagere est manquante",
  ventilationsSelectedCount:
    "Vous devez selectionné une seule ventilation de prix",
  renteEtMontantOver:
    "L'augmentation de la mensualité en cas de départ anticipé et le montant mensuel ne peuvent pas être renseignés en même temps",
  renteEtMontantMiss:
    "Au moins l'augmentation de la mensualité en cas de départ anticipé ou le montant mensuel doit être renseigné",
  indice: "L'indice est manquant",
  consentementVendeur: "Veuillez selectionner le vendeur pour le consentement",
  clauseDepartAnticipeMontant:
    "Le montant de la clause de départ anticipé est manquant",
  autreConditionsText: "Le texte des autres conditions est manquant",
  typeDeVentesRecherche:
    "Vous devez choisir au moins un type de vente pour votre mandat de recherche",
  ventilationsRenteBouquet: "Vous devez compléter les rentes et les bouquets",
  ventilationsRenteComptant:
    "Vous devez compléter les informations de la ventilation comptant",
  /** Champs agences */
  nomGerant: "Le nom du gérant de l'agence est manquant",
  tvaIntra: "La TVA Intracommunautaire n'est pas renseignée",
  garantNom: "Le nom du Garant n'est pas renseigné",
  garantAdresse: "L'adresse du Garant n'est pas renseignée",
  raisonSociale: "La raison sociale n'est pas renseignée",
  statutRepresentant: "Le statut du réprésentant n'est pas renseigné",
  tel: "Le téléphone de l'agence n'est pas renseigné",
  statutJuridique: "Le statut juridique n'est pas renseigné",
  carteT: "La carte T de l'agence n'est pas renseignée",
  carteTDeliveredBy: "La date de délivrance de la carte T n'est pas renseignée",
  assureurNom: "Le nom de l'assureur n'est pas renseignée",
  assureurAdresse: "L'adresse de l'assureur n'est pas renseignée",
  mediateurNom: "Le nom du Médiateur n'est pas renseignée",
  mediateurAdresse: "L'adresse du Médiateur n'est pas renseignée",
  mediateurSite: "Le site du Médiateur n'est pas renseigné",
  mediateurEmail: "Le mail du Médiateur n'est pas renseigné",
  designationBienRecherche:
    "La désignation du bien recherché n'est pas renseignée",
  localisationBienRecherche:
    "La localisatoin du bien recherché n'est pas renseignée",
  bouquetRecherche: "le bouquet n'est pas renseigné",
  renteViagereRecherche: "La rente viagére n'est pas renseignée",
  descriptionViagerLibre: "La description du viager libre n'est pas renseignée",
  descriptionViagerOccupe:
    "La description du viager occupé n'est pas renseignée",
};

/**
 *
 * @param {*} errors
 * @returns
 */
export function swalErrorsLisHtmlBuilder(errors) {
  let html =
    "<span>Les erreurs suivantes ont été détectées sur le mandat :</span><ul>";

  errors.forEach((error) => {
    let index = null;
    let entity = null;
    let addline = "";
    if (error.includes("_")) {
      index = error.split("_")[1];
      entity = error.split("_")[2];
      error = error.split("_")[0];
      addline = handleCollection(error, index, entity);
    }

    html += `<li style="font-style:italic">${errorToHtml(
      error
    )} ${addline}</li>`;
  });

  html += "</ul>";

  html +=
    "<h3>Veuillez corriger ces erreurs afin de générer le fichier pdf</h3><ul>";

  return html;
}

/**
 *
 * @param {*} error
 * @returns
 */
function errorToHtml(error) {
  return errorsTrad[error];
}

/**
 *
 * @param {*} error
 * @param {*} index
 * @param {*} entity
 * @returns
 */
function handleCollection(error, index, entity) {
  if (index) {
    return ` pour le/la ${entity} numéro ${index}`;
  }
  return "";
}
