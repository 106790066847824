import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  _ACTIONS,
  _BAIL_A_NOURRITURE,
  _COLORS,
  _EN_COURS_SIGNATURE,
  _GESTION_VIAGERE,
  _SIGNE,
  _VENTE_TRADITIONNELLE,
  _VIAGER_MIXTE,
} from "../../utils/_constants";
import { _STYLES } from "../../utils/_style_constants";
import DescriptionBienMandat from "../forms/mandats/DescriptionBienMandat";
import RemunerationMandataire from "../forms/mandats/RemunerationMandataire";
import ConditionsParticulieres from "../forms/mandats/ConditionsParticulieres";
import VentilationPrix from "../forms/mandats/VentilationPrix";
import CreationMandatVente from "../forms/mandats/CreationMandatVente";
import AnnexesDocumentaires from "../forms/mandats/AnnexesDocumentaires";
import ValorisationPrestations from "../forms/mandats/ValorisationPrestations";
import { useForm } from "react-hook-form";
import { RequestHelper } from "../../utils/requestHelper";
import Mandant from "../forms/mandats/Mandant";
import { useParams } from "react-router";
import { editInstantRequest } from "../../utils/formHelper";
import BoxActionMandat from "../forms/mandats/BoxActionMandat";
import { UserContext } from "../contexts/UserContext";
import GestionViagereInfos from "../forms/mandats/GestionViagereInfos";
import GestionViagereRemuneration from "../forms/mandats/GestionViagereRemuneration";
import _ from "lodash";

function MandatVente() {
  const { user } = React.useContext(UserContext);
  const [typeVentes, setTypeVentes] = useState([]);
  const [typeMandants, setTypeMandants] = useState([]);
  const [civilites, setCivilites] = useState([]);
  const [typeBiens, setTypeBiens] = useState([]);
  const [indices, setIndices] = useState([]);

  const [users, setUsers] = useState([]);
  const [typeDocuments, setTypeDocuments] = useState([]);
  const [currentMandat, setCurrentMandat] = useState([]);
  const [refreshMandat, setRefreshMandat] = useState(0);
  const currentIdUrl = useParams();
  const requestHelper = new RequestHelper();

  const mandantListNonSignataire = currentMandat?.mandants?.filter(
    (mandants) =>
      mandants.onlyForSignature === false || mandants.onlyForSignature === null
  );
  const mandantListSignataire = currentMandat?.mandants?.filter(
    (mandants) => mandants.onlyForSignature === true
  );

  /* GET User Entity */
  useEffect(() => {
    getIndice();
    getTypeDocument();
    getTypeDeBien();
    getCivilite();
    getTypeMandant();
    getUsers();
  }, []);

  const {
    register,
    formState: { errors },
  } = useForm({ mode: "onChange	" });

  useEffect(() => {
    getUsers();
    getTypeDeBien();
    getTypeDeVente();
    /* GET Mandat by Id */
    getMandatById();
  }, [refreshMandat]);

  /* Va en haut de la page à l'ouverture */
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const getTypeDeVente = () => {
    requestHelper.get("/api/type_ventes").then((response) => {
      /** Filtre Pour gestion Viagére à mettre uniquement pour admin et sadmin */
      let arrayList = response.data["hydra:member"];
      let finalArrayList = [];
      arrayList.forEach((item) => {
        if (user.profil === "SUSER" || user.profil === "USER") {
          if (
            item.nom !== _GESTION_VIAGERE &&
            item.nom !== _VENTE_TRADITIONNELLE &&
            item.nom !== _BAIL_A_NOURRITURE
          ) {
            finalArrayList.push(item);
          }
        } else {
          finalArrayList.push(item);
        }
      });
      setTypeVentes(finalArrayList);
    });
  };

  const getTypeMandant = () => {
    requestHelper.get("/api/type_mandants").then((response) => {
      setTypeMandants(response.data["hydra:member"]);
    });
  };

  const getCivilite = () => {
    requestHelper.get("/api/civilites").then((response) => {
      setCivilites(response.data["hydra:member"]);
    });
  };

  const getTypeDeBien = () => {
    requestHelper.get("/api/type_biens").then((response) => {
      setTypeBiens(response.data["hydra:member"]);
    });
  };

  const getIndice = () => {
    requestHelper.get("/api/indices").then((response) => {
      setIndices(response.data["hydra:member"]);
    });
  };

  const getUsers = () => {
    // on récupére l'agence du mandat
    let agenceAPi = currentMandat?.agence ? currentMandat.agence.id : "";
    // si le mandat est null, on récupére l'agence de l'utilisateur
    if (agenceAPi === "" && !_.isUndefined(user.agencesList)) {
      agenceAPi = user.agencesList;
      // on supprime la derniére virgule
      if (agenceAPi[agenceAPi.length - 1] === ",") {
        agenceAPi = agenceAPi.slice(0, -1);
      }
    }
    requestHelper
      .get("/api/users?agences[]=" + agenceAPi + "&represent=true&actif=true")
      .then((response) => {
        setUsers(response.data["hydra:member"]);
      });
  };

  const getTypeDocument = () => {
    requestHelper.get("/api/type_documents").then((response) => {
      setTypeDocuments(response.data["hydra:member"]);
    });
  };

  const getMandatById = () => {
    requestHelper
      .doRequest(_ACTIONS.GET, `/api/mandats/${currentIdUrl.id}`)
      .then((response) => {
        if (response.status === 200) {
          setCurrentMandat(response.data);
        }
      });
  };

  /** Fonction pour refresh le mandat  */
  const refresh = () => {
    setRefreshMandat(refreshMandat + 1);
  };

  const disabledSign =
    currentMandat?.statut?.nom === _SIGNE ||
    currentMandat?.statut?.nom === _EN_COURS_SIGNATURE;

  return (
    <>
      <Grid
        container
        spacing={2}
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          border: "2px solid rgb(238, 238, 238)",
          borderRadius: "10px",
          gap: "1rem",
          padding: 4,
          margin: 1,
        }}
      >
        <Grid sx={{ width: "100%" }}>
          <CreationMandatVente
            register={register}
            currentMandat={currentMandat}
            setCurrentMandat={setCurrentMandat}
            typeVentes={typeVentes}
            errors={errors}
            setTypeVentes={setTypeVentes}
            refresh={refresh}
            disabledSign={disabledSign}
          />
        </Grid>
        <Grid sx={{ ..._STYLES.sxGridChild }}>
          <Mandant
            register={register}
            errors={errors}
            typeMandants={typeMandants}
            setTypeMandants={setTypeMandants}
            currentMandat={currentMandat}
            setCurrentMandat={setCurrentMandat}
            civilites={civilites}
            refresh={refresh}
            requestHelper={requestHelper}
            disabledSign={disabledSign}
            mandantListNonSignataire={mandantListNonSignataire}
          />
        </Grid>
        {currentMandat?.typeVente?.nom !== _GESTION_VIAGERE && (
          <Grid sx={{ ..._STYLES.sxGridChild }}>
            <DescriptionBienMandat
              register={register}
              errors={errors}
              typeBiens={typeBiens}
              currentMandat={currentMandat}
              setCurrentMandat={setCurrentMandat}
              refresh={refresh}
              disabledSign={disabledSign}
            />
          </Grid>
        )}
        {currentMandat?.typeVente?.nom === _GESTION_VIAGERE && (
          <Grid sx={{ ..._STYLES.sxGridChild }}>
            <GestionViagereInfos
              register={register}
              errors={errors}
              typeBiens={typeBiens}
              currentMandat={currentMandat}
              setCurrentMandat={setCurrentMandat}
              refresh={refresh}
              disabledSign={disabledSign}
            />
          </Grid>
        )}
        {currentMandat?.typeVente?.nom === _BAIL_A_NOURRITURE && (
          <Grid sx={{ ..._STYLES.sxGridChild }}>
            <ValorisationPrestations
              register={register}
              errors={errors}
              typeBiens={typeBiens}
              currentMandat={currentMandat}
              setCurrentMandat={setCurrentMandat}
              refresh={refresh}
              disabledSign={disabledSign}
            />
          </Grid>
        )}

        {currentMandat?.typeVente?.nom !== _GESTION_VIAGERE && (
          <Grid sx={{ ..._STYLES.sxGridChild, backgroundColor: "#f7e0eb" }}>
            <VentilationPrix
              register={register}
              errors={errors}
              indices={indices}
              currentMandat={currentMandat}
              setCurrentMandat={setCurrentMandat}
              refresh={refresh}
              requestHelper={requestHelper}
              disabledSign={disabledSign}
            />
          </Grid>
        )}

        <Grid container spacing={1} padding={4}>
          <Grid item xs={12} md={12}>
            <Typography
              variant="h5"
              style={{
                fontWeight: "bold",
                color: _COLORS.primary,
              }}
            >
              Rémunération du mandataire
            </Typography>
          </Grid>
          {currentMandat?.typeVente?.nom === _VIAGER_MIXTE && (
            <RemunerationMandataire
              currentMandat={currentMandat}
              setCurrentMandat={setCurrentMandat}
              refresh={refresh}
              register={register}
              field={"occupé"}
              disabledSign={disabledSign}
            />
          )}
          {currentMandat?.typeVente?.nom !== _GESTION_VIAGERE && (
            <RemunerationMandataire
              currentMandat={currentMandat}
              setCurrentMandat={setCurrentMandat}
              refresh={refresh}
              register={register}
              field={"libre"}
              disabledSign={disabledSign}
            />
          )}
          {currentMandat?.typeVente?.nom === _GESTION_VIAGERE && (
            <GestionViagereRemuneration
              register={register}
              errors={errors}
              typeBiens={typeBiens}
              currentMandat={currentMandat}
              setCurrentMandat={setCurrentMandat}
              refresh={refresh}
              disabledSign={disabledSign}
            />
          )}
        </Grid>

        <Grid sx={{ ..._STYLES.sxGridChild }}>
          <ConditionsParticulieres
            register={register}
            errors={errors}
            requestHelper={requestHelper}
            civilites={civilites}
            currentMandat={currentMandat}
            setCurrentMandat={setCurrentMandat}
            refresh={refresh}
            disabledSign={disabledSign}
            mandantListSignataire={mandantListSignataire}
          />
        </Grid>
        <Grid sx={{ ..._STYLES.sxGridChild }}>
          <Box sx={{ flexGrow: 1, padding: 4 }}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography
                  variant="h5"
                  style={{
                    fontWeight: "bold",
                    color: _COLORS.primary,
                  }}
                >
                  Signatures
                </Typography>
              </Grid>
              <Grid item xs={12} sx={{ display: "flex", gap: 2 }}>
                <Typography>Signataire des mandataires</Typography>
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <fieldset
                      disabled={
                        currentMandat?.type === "avenant" || disabledSign
                      }
                    >
                      <InputLabel> Signataire :</InputLabel>
                      <Select
                        label="Signataire :"
                        sx={{ border: "none" }}
                        size="small"
                        {...register("signataire", { required: false })}
                        value={
                          currentMandat?.signataire
                            ? currentMandat?.signataire?.["@id"]
                            : ""
                        }
                        fullWidth
                        disabled={disabledSign}
                        onChange={(e) => {
                          let value =
                            e.target.value === "null" ? null : e.target.value;
                          let m = editInstantRequest(
                            value,
                            currentMandat["@id"],
                            "signataire"
                          );

                          m.then((m) => {
                            if (m["@context"] === "/api/contexts/Error") {
                              refresh();
                            } else {
                              setCurrentMandat(m);
                            }
                          });
                        }}
                      >
                        <MenuItem value="null">Aucun Signataire</MenuItem>
                        {users.map((u, i) => (
                          <MenuItem key={i} value={u["@id"]}>
                            {u.prenom + " " + u.nom}
                          </MenuItem>
                        ))}
                      </Select>
                    </fieldset>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <Grid sx={_STYLES.sxGridChild}>
          <AnnexesDocumentaires
            errors={errors}
            register={register}
            typeDocuments={typeDocuments}
            refresh={refresh}
            currentMandat={currentMandat}
            disabledSign={disabledSign}
          />
        </Grid>
        <BoxActionMandat
          requestHelper={requestHelper}
          refresh={refresh}
          currentMandat={currentMandat}
          disabledSign={disabledSign}
        ></BoxActionMandat>
      </Grid>
    </>
  );
}

export default MandatVente;
