import _ from "lodash";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import {
  _COLORS,
  _NUE_PROPRIETE,
  _VENTE_A_TERME_LIBRE,
  _VENTE_A_TERME_OCCUPE,
  _VIAGER_COMPTANT,
  _VIAGER_MIXTE,
  _VIAGER_LIBRE,
  _VIAGER_OCCUPE,
  _GESTION_VIAGERE,
  _VENTE_TRADITIONNELLE,
  _BAIL_A_NOURRITURE,
} from "../../../utils/_constants";
import { _STYLES } from "../../../utils/_style_constants";
import { editInstantRequest } from "../../../utils/formHelper";
import { createToast } from "../../../utils/toast";
import Ventilation from "./Ventilation";
import { NumericFormat } from "react-number-format";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import MouseOverPopover from "../MouseOverPopover";
import HelpIcon from "@mui/icons-material/Help";
import ModalitePaiement from "./ModalitePaiement";

// liste des types de vente ayant besoin des champs comptant et mensualités avec durée
// Modifier dans le back MandatService->setVentilations dans le switch
export let arrayMensualite = [];
arrayMensualite.push(_VIAGER_MIXTE); // Retiré le 09/07/24 // Remis le 5/9/2024
arrayMensualite.push(_VIAGER_OCCUPE); // Ajouté  le 4/9/24
arrayMensualite.push(_BAIL_A_NOURRITURE); // Ajouté  le 4/9/24

// liste des types de vente ayant besoin du champ augmentation de la rente/mensualite
let arrayAugmentation = [];
arrayAugmentation.push(_VIAGER_OCCUPE);
arrayAugmentation.push(_BAIL_A_NOURRITURE);
arrayAugmentation.push(_VIAGER_MIXTE);
arrayAugmentation.push(_VIAGER_LIBRE); // ajouté  le 29/07/24 // retiré le 6/8/24
arrayAugmentation.push(_GESTION_VIAGERE);
// Liste des types de ventes pouvant visualiser les modalités de paiement
let arrayModalitePaiement = [];
arrayModalitePaiement.push(_VIAGER_OCCUPE);
arrayModalitePaiement.push(_VENTE_A_TERME_OCCUPE);
arrayModalitePaiement.push(_BAIL_A_NOURRITURE);
arrayModalitePaiement.push(_NUE_PROPRIETE);

// Liste des types de ventes pouvant avoir un indice
let arrayIndice = [];
arrayIndice.push(_VIAGER_OCCUPE);
arrayIndice.push(_BAIL_A_NOURRITURE);
arrayIndice.push(_VIAGER_LIBRE);
arrayIndice.push(_VIAGER_MIXTE);
arrayIndice.push(_NUE_PROPRIETE);
arrayIndice.push(_VENTE_A_TERME_OCCUPE);
arrayIndice.push(_VENTE_A_TERME_LIBRE);

function VentilationPrix({
  register,
  errors,
  indices,
  currentMandat,
  setCurrentMandat,
  refresh,
  requestHelper,
  disabledSign = false,
}) {
  const mandatVenteComptant =
    currentMandat?.typeVente?.nom === _VIAGER_COMPTANT;
  const mandatVenteNuePropriete =
    currentMandat?.typeVente?.nom === _NUE_PROPRIETE;
  const mandatVenteTermeLibre =
    currentMandat?.typeVente?.nom === _VENTE_A_TERME_LIBRE;
  const mandatVenteMixte = currentMandat?.typeVente?.nom === _VIAGER_MIXTE;
  const mandatVenteTraditionnelle =
    currentMandat?.typeVente?.nom === _VENTE_TRADITIONNELLE;
  const mandatVenteTermeOccupee =
    currentMandat?.typeVente?.nom === _VENTE_A_TERME_OCCUPE;

  const updateValeurBien = (value) => {
    currentMandat.bien.valeurBiens.filter(
      (v) => v.mandat === currentMandat["@id"]
    )[0].valeurVenale = value;
    setCurrentMandat({
      ...currentMandat,
      bien: {
        ...currentMandat.bien,
        valeursBiens: {
          ...currentMandat.bien.valeursBiens,
        },
      },
    });
  };

  const showRente = !!(
    currentMandat?.typeVente?.nom &&
    !arrayMensualite.includes(currentMandat.typeVente.nom)
  );

  const createVentilation = (e, mixte = false) => {
    let data = {
      selected: false,
      bouquet: 0,
      renteViagere: 0,
      mandat: currentMandat["@id"],
    };

    if (mixte) {
      mixte === "occupe"
        ? (data.isVMViagerOccupe = true)
        : (data.isVMViagerLibre = true);
    }

    requestHelper.doRequest("POST", "/api/ventilations", data).then((res) => {
      if (res.status === 201) {
        createToast("success", "Ventilation créée avec succès");

        setCurrentMandat({
          ...currentMandat,
          ventilations: [...currentMandat.ventilations, res.data],
        });
      } else {
        createToast("error", "Erreur lors de la création de la ventilation");
      }
    });
  };

  useEffect(() => {}, [currentMandat]);

  return (
    <>
      <Box sx={{ padding: 4, flexGrow: 1 }}>
        <Grid container columnSpacing={1} rowSpacing={1} padding={2}>
          {currentMandat.type === "avenant" && (
            <>
              <Grid
                item
                md={12}
                xs={12}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <Typography
                  variant="h5"
                  style={{
                    fontWeight: "bold",
                    color: _COLORS.secondary,
                  }}
                >
                  MODIFICATIONS DU MANDAT
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="h5"
                  style={{
                    fontWeight: "bold",
                    color: _COLORS.primary,
                  }}
                >
                  Description du bien
                </Typography>
              </Grid>

              <Grid sx={{ marginTop: "1em" }} item md={2} xs={12}>
                <Typography sx={{ ..._STYLES.typographyLabelTitle }}>
                  Valeur vénale*
                </Typography>
              </Grid>

              <Grid
                sx={{ marginTop: "1em" }}
                item
                md={4}
                xs={11}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <NumericFormat
                  thousandSeparator=" "
                  customInput={TextField}
                  allowNegative={false}
                  decimalScale={2}
                  allowedDecimalSeparators={["."]}
                  disabled={currentMandat?.type !== "avenant" || disabledSign}
                  size="small"
                  fullWidth
                  displayType="input"
                  id="valeurVenale"
                  label="Valeur Vénale"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">€</InputAdornment>
                    ),
                  }}
                  value={
                    currentMandat?.bien?.valeurBiens
                      ? currentMandat.bien.valeurBiens.filter(
                          (v) => v.mandat === currentMandat["@id"]
                        )[0].valeurVenale
                      : ""
                  }
                  {...register("valeurVenale", {
                    onChange: (e) => {
                      updateValeurBien(e.target.value.replace(/ /g, ""));
                    },
                    onBlur: (e) => {
                      editInstantRequest(
                        e.target.value,
                        currentMandat?.bien?.valeurBiens.filter(
                          (v) => v.mandat === currentMandat["@id"]
                        )[0]["@id"],
                        "valeurVenale"
                      );

                      refresh();
                    },
                    validate: (value) => !_.isNaN(value) || _.isEmpty(value),
                    setValueAs: (value) => {
                      return _.isEmpty(value) ? "" : parseFloat(value);
                    },
                  })}
                />
                {errors.valeurVenale && (
                  <Typography sx={_STYLES.errorMessage}>
                    Cette valeur doit être une donné numérique
                  </Typography>
                )}
              </Grid>
            </>
          )}
          <Grid item xs={12} display={"flex"} gap={2} alignItems={"baseline"}>
            <Typography
              variant="h5"
              style={{
                fontWeight: "bold",
                color: _COLORS.primary,
              }}
            >
              Ventilation du prix
            </Typography>
            <MouseOverPopover
              sx={{
                marginLeft: "10px",
              }}
              title={<HelpIcon />}
              popoverText=" Cet espace est réservé à mettre plusieurs hypothèses de ventilation de prix "
            />
          </Grid>
          {arrayModalitePaiement.includes(currentMandat?.typeVente?.nom) && (
            <Grid item md={12} xs={12}>
              <ModalitePaiement
                currentMandat={currentMandat}
                setCurrentMandat={setCurrentMandat}
                refresh={refresh}
                register={register}
                requestHelper={requestHelper}
                disabledSign={disabledSign}
              ></ModalitePaiement>
            </Grid>
            // )
          )}
          {/* LISTE DES VENTILATIONS  */}
          {currentMandat?.typeVente?.nom !== _VIAGER_MIXTE && (
            <Grid item md={12} xs={12}>
              <Typography
                variant="h6"
                fontWeight={"semibold"}
                sx={{
                  color: _COLORS.primary,
                }}
              >
                {currentMandat?.ventilations?.length > 0
                  ? currentMandat?.typeVente?.nom
                  : "Aucune ventilation"}
              </Typography>
            </Grid>
          )}
          {/* gestion du comptant dans les ventilations*/}
          {currentMandat?.ventilations?.length > 0 &&
            currentMandat?.typeVente?.nom !== _VIAGER_MIXTE &&
            currentMandat?.ventilations
              .filter((row, i) => {
                return !_.isNull(row.comptant);
              })
              .map((row, i) => {
                return (
                  <Ventilation
                    row={row}
                    i={i}
                    key={i}
                    register={register}
                    currentMandat={currentMandat}
                    setCurrentMandat={setCurrentMandat}
                    refresh={refresh}
                    requestHelper={requestHelper}
                    disabledSign={disabledSign}
                  ></Ventilation>
                );
              })}
          {/* gestion du bouquet dans les ventilations*/}
          {currentMandat?.ventilations?.length > 0 &&
            currentMandat?.typeVente?.nom !== _VIAGER_MIXTE &&
            currentMandat?.ventilations
              .filter((row, i) => {
                return !_.isNull(row.bouquet);
              })
              .map((row, i) => {
                return (
                  <Ventilation
                    row={row}
                    i={i}
                    key={i}
                    register={register}
                    currentMandat={currentMandat}
                    setCurrentMandat={setCurrentMandat}
                    refresh={refresh}
                    requestHelper={requestHelper}
                    disabledSign={disabledSign}
                  ></Ventilation>
                );
              })}
          {/* gestion de la duree d'occupation dans les ventilations pour les VATO*/}
          {currentMandat?.ventilations?.length > 0 &&
            (mandatVenteTermeOccupee ||
              mandatVenteTermeLibre ||
              mandatVenteNuePropriete) &&
            currentMandat?.ventilations
              .filter((row, i) => {
                return row.occupationDuree;
              })
              .map((row, i) => {
                return (
                  <Ventilation
                    row={row}
                    i={i}
                    key={i}
                    register={register}
                    currentMandat={currentMandat}
                    setCurrentMandat={setCurrentMandat}
                    refresh={refresh}
                    requestHelper={requestHelper}
                    disabledSign={disabledSign}
                  ></Ventilation>
                );
              })}
          {/* FIN DES VENTILATIONS */}
          {/** BOUTON AJOUTER */}
          {(currentMandat?.ventilations?.length > 0 || !showRente) &&
          !mandatVenteComptant &&
          !mandatVenteNuePropriete &&
          !mandatVenteTermeLibre &&
          !mandatVenteMixte &&
          !mandatVenteTraditionnelle &&
          !mandatVenteTermeOccupee ? (
            <Grid container>
              <Grid item md={10} xs={10}></Grid>
              <Grid item md={2} xs={2} sx={{}}>
                {!disabledSign && (
                  <Button
                    size="small"
                    sx={{ top: -42, color: "black" }}
                    onClick={createVentilation}
                  >
                    <AddCircleIcon sx={{ marginRight: 1 }} /> Ajouter une ligne
                  </Button>
                )}
              </Grid>
            </Grid>
          ) : null}
          {/** FIN BOUTON AJOUTER */}

          {/* LISTE DES VENTILATIONS VIAGER MIXTE */}

          {currentMandat?.typeVente?.nom === _VIAGER_MIXTE && (
            <Grid item md={12} xs={12}>
              <Typography
                variant="h6"
                fontWeight={"semibold"}
                sx={{
                  color: _COLORS.primary,
                }}
              >
                Viager Libre
              </Typography>
            </Grid>
          )}
          {/* Viager Mixte LIBRE BOUQUET */}
          {currentMandat?.ventilations?.length > 0 &&
            currentMandat?.typeVente?.nom === _VIAGER_MIXTE &&
            currentMandat?.ventilations
              .filter((row, i) => {
                return !_.isNull(row.bouquet) && row.isVMViagerLibre === true;
              })
              .map((row, i) => {
                return (
                  <Ventilation
                    row={row}
                    i={row.id}
                    key={row.id}
                    register={register}
                    currentMandat={currentMandat}
                    setCurrentMandat={setCurrentMandat}
                    refresh={refresh}
                    requestHelper={requestHelper}
                    special={"mixteLibre"}
                    disabledSign={disabledSign}
                  ></Ventilation>
                );
              })}
          {/* Add Line */}
          {currentMandat?.typeVente?.nom === _VIAGER_MIXTE && (
            <Grid container>
              <Grid item md={10} xs={10}></Grid>
              <Grid item md={2} xs={2} sx={{}}>
                {!disabledSign && (
                  <Button
                    size="small"
                    sx={{ top: -42, color: "black" }}
                    onClick={(e) => createVentilation(e, "libre")}
                    disabled={
                      currentMandat?.ventilations?.filter((row, i) => {
                        return !_.isNull(row.isVMViagerLibre);
                      }).length > 4
                        ? true
                        : false
                    }
                  >
                    <AddCircleIcon sx={{ marginRight: 1 }} /> Ajouter une ligne
                  </Button>
                )}
              </Grid>
            </Grid>
          )}
          {currentMandat?.typeVente?.nom === _VIAGER_MIXTE && (
            <Grid item md={12} xs={12}>
              <Typography
                variant="h6"
                fontWeight={"semibold"}
                sx={{
                  color: _COLORS.primary,
                }}
              >
                Viager Occupé
              </Typography>
            </Grid>
          )}
          {/* Viager Mixte OCCUPE BOUQUET */}
          {currentMandat?.ventilations?.length > 0 &&
            currentMandat?.typeVente?.nom === _VIAGER_MIXTE &&
            currentMandat?.ventilations
              .filter((row, i) => {
                return !_.isNull(row.bouquet) && row.isVMViagerOccupe === true;
              })
              .map((row, i) => {
                return (
                  <Ventilation
                    row={row}
                    i={row.id}
                    key={row.id}
                    register={register}
                    currentMandat={currentMandat}
                    setCurrentMandat={setCurrentMandat}
                    refresh={refresh}
                    requestHelper={requestHelper}
                    special={"mixteOccupe"}
                    disabledSign={disabledSign}
                  ></Ventilation>
                );
              })}
          {/* Add Line */}
          {currentMandat?.typeVente?.nom === _VIAGER_MIXTE && (
            <Grid container>
              <Grid item md={10} xs={10}></Grid>
              <Grid item md={2} xs={2} sx={{}}>
                {!disabledSign && (
                  <Button
                    size="small"
                    sx={{ top: -42, color: "black" }}
                    onClick={(e) => createVentilation(e, "occupe")}
                    disabled={
                      currentMandat?.ventilations?.filter((row, i) => {
                        return !_.isNull(row.isVMViagerOccupe);
                      }).length > 4
                        ? true
                        : false
                    }
                  >
                    <AddCircleIcon sx={{ marginRight: 1 }} /> Ajouter une ligne
                  </Button>
                )}
              </Grid>
            </Grid>
          )}
          {/* PAS SUR DE GARDER */}
          {/* {currentMandat?.ventilations?.length > 0 &&
            currentMandat?.typeVente?.nom === "Viager Mixte" &&
            currentMandat?.ventilations
              .filter((row, i) => {
                return (
                  !_.isNull(row.mensualite) && !_.isNull(row.isVMViagerLibre)
                );
              })
              .map((row, i) => {
                return (
                  <Ventilation
                    row={row}
                    i={i + "-libre"}
                    register={register}
                    currentMandat={currentMandat}
                    setCurrentMandat={setCurrentMandat}
                    refresh={refresh}
                    requestHelper={requestHelper}
                  ></Ventilation>
                );
              })} */}

          {/* FIN DES VENTILATIONS */}

          {/** INDICE ET AUGMENTATION */}
          {currentMandat?.typeVente?.nom && (
            <>
              {/** AUGMENTATION */}
              {arrayMensualite.includes(currentMandat.typeVente.nom) && (
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    mt: 2,
                  }}
                >
                  <Grid
                    item
                    md={7}
                    xs={12}
                    display={"flex"}
                    flexDirection={"column"}
                    padding={1}
                    gap={2}
                    justifyContent={"center"}
                    sx={{
                      border: "2px solid rgb(238, 238, 238)",
                      borderRadius: "10px",
                      borderColor: "#d4bdc8",
                      width: "100%",
                    }}
                  >
                    <Box display={"flex"}>
                      <Grid
                        item
                        md={8}
                        xs={12}
                        display={"flex"}
                        justifyContent={"center"}
                      >
                        <Typography>
                          {!showRente
                            ? "Augmentation de la mensualité en cas de départ anticipé"
                            : "Augmentation de la rente en cas de départ anticipé"}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        md={4}
                        xs={12}
                        display={"flex"}
                        flexDirection={"column"}
                        alignItems={"center"}
                        gap={1}
                      >
                        <NumericFormat
                          thousandSeparator=" "
                          allowNegative={false}
                          customInput={TextField}
                          decimalScale={2}
                          displayType="input"
                          disabled={disabledSign}
                          allowedDecimalSeparators={["."]}
                          size="small"
                          id="renteAugmentation"
                          label={
                            showRente
                              ? "Augmentation Rente Viagère"
                              : "Augmentation Mensualité"
                          }
                          value={
                            currentMandat?.renteAugmentation
                              ? currentMandat?.renteAugmentation
                              : ""
                          }
                          {...register("renteAugmentation", {
                            min: 0,
                            max: 100,
                            onChange: (e) => {
                              setCurrentMandat({
                                ...currentMandat,
                                renteAugmentation: e.target.value.replace(
                                  / /g,
                                  ""
                                ),
                              });
                            },
                            onBlur: (e) => {
                              let m = editInstantRequest(
                                parseFloat(e.target.value.replace(/ /g, "")),
                                currentMandat["@id"],
                                "renteAugmentation"
                              );

                              m.then((m) => {
                                if (m["@context"] === "/api/contexts/Error") {
                                  refresh();
                                } else {
                                  setCurrentMandat(m);
                                }
                              });
                            },
                            validate: (value) =>
                              !_.isNaN(value) || _.isEmpty(value),

                            min: {
                              value: 0,
                              message:
                                "Le pourcentage ne peut pas être négatif",
                            },
                            setValueAs: (value) => {
                              return _.isEmpty(value) ? "" : parseFloat(value);
                            },
                          })}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">%</InputAdornment>
                            ),
                          }}
                        />
                        {errors.renteAugmentation && (
                          <Typography color={"red"}>
                            {errors.renteAugmentation.message}
                          </Typography>
                        )}
                      </Grid>
                    </Box>
                    <Grid
                      item
                      xs={12}
                      md={12}
                      display={"flex"}
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      <Typography fontWeight={"bold"}>OU</Typography>
                    </Grid>
                    <Box display={"flex"}>
                      <Grid
                        item
                        md={8}
                        xs={12}
                        display={"flex"}
                        justifyContent={"center"}
                      >
                        <Typography>Montant mensuel</Typography>
                      </Grid>
                      <Grid
                        item
                        md={4}
                        xs={12}
                        display={"flex"}
                        alignItems={"center"}
                        gap={1}
                      >
                        <NumericFormat
                          thousandSeparator=" "
                          customInput={TextField}
                          allowNegative={false}
                          decimalScale={2}
                          displayType="input"
                          disabled={disabledSign}
                          allowedDecimalSeparators={["."]}
                          size="small"
                          id="montantAugmentation"
                          label="Montant Mensuel"
                          value={
                            currentMandat?.montantAugmentation
                              ? currentMandat?.montantAugmentation
                              : ""
                          }
                          {...register("montantAugmentation", {
                            onChange: (e) => {
                              setCurrentMandat({
                                ...currentMandat,
                                montantAugmentation: e.target.value.replace(
                                  / /g,
                                  ""
                                ),
                              });
                            },
                            onBlur: (e) => {
                              let m = editInstantRequest(
                                parseFloat(e.target.value.replace(/ /g, "")),
                                currentMandat["@id"],
                                "montantAugmentation"
                              );

                              m.then((m) => {
                                if (m["@context"] === "/api/contexts/Error") {
                                  refresh();
                                } else {
                                  setCurrentMandat(m);
                                }
                              });
                            },
                          })}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">€</InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                    </Box>
                  </Grid>
                </Box>
              )}
              {/** FIN AUGMENTATION */}
              {/** INDICE */}
              {arrayIndice.includes(currentMandat.typeVente.nom) && (
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  width={"100%"}
                  marginTop={2}
                >
                  <Grid item md={2} xs={12} marginTop={2}>
                    <Typography>
                      {mandatVenteTermeOccupee
                        ? "Indexation annuelle de la mensualité" +
                          (!mandatVenteTermeOccupee ? "*" : "")
                        : "Indexation annuelle de la rente*"}
                    </Typography>
                  </Grid>
                  <Grid item md={7} xs={12} marginTop={2}>
                    <FormControl fullWidth size="small">
                      <Select
                        disabled={disabledSign}
                        value={
                          currentMandat?.indice
                            ? currentMandat?.indice["@id"]
                            : ""
                        }
                        onChange={(e) => {
                          let m = editInstantRequest(
                            e.target.value,
                            currentMandat["@id"],
                            "indice"
                          );

                          m.then((m) => {
                            if (m["@context"] === "/api/contexts/Error") {
                              refresh();
                            } else {
                              setCurrentMandat(m);
                            }
                          });
                        }}
                      >
                        {indices.map((indice, i) => (
                          <MenuItem key={i} value={indice["@id"]}>
                            {indice.nom}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  {/** pour la VATO en cas de non indice */}
                  {(mandatVenteTermeOccupee ||
                    mandatVenteNuePropriete ||
                    mandatVenteTermeLibre) && (
                    <Grid item md={2} xs={12} marginTop={2} paddingLeft={2}>
                      <FormControlLabel
                        sx={{
                          fontWeight: "bold",
                          fontSize: "1.0rem",
                        }}
                        control={
                          <Checkbox
                            name={"noIndice"}
                            checked={currentMandat?.noIndice}
                            disabled={disabledSign}
                            onChange={(e) => {
                              let m = editInstantRequest(
                                e.target.checked,
                                currentMandat["@id"],
                                "noIndice"
                              );

                              m.then((m) => {
                                if (m["@context"] === "/api/contexts/Error") {
                                  refresh();
                                } else {
                                  setCurrentMandat(m);
                                }
                              });
                            }}
                          />
                        }
                        label={" Pas d'indexation "}
                      />
                    </Grid>
                  )}
                </Box>
              )}
              {/** FIN INDICE */}
            </>
          )}
          {/** FIN INDICE ET AUGMENTATION */}
        </Grid>
      </Box>
    </>
  );
}

export default VentilationPrix;
